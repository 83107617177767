

























import { PendingReservation } from '@/composables/useBookedResources/types';
import { defineComponent, PropType } from '@/plugins/composition';
import { formatPrice } from '@/utils/number';
import { formatTimeRange } from '@/utils/date';
import { useApi } from '@/composables/useApi';

export default defineComponent({
  name: 'PendingReservationListItem',
  props: {
    reservation: {
      type: Object as PropType<PendingReservation>,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  setup(props, { emit }) {
    const cancel = async () => {
      const { $delete } = useApi();

      await $delete(`/reservations/${props.reservation.uuid}`);

      emit('deleted');
    };
    return {
      formatPrice,
      formatTimeRange,
      cancel,
    };
  },
});
